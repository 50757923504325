import React from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'

import '../styles/global.css'

const BaseLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
`

const GlobalStyle = createGlobalStyle`
  body {
    font-size: 2rem;
  }
`

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <BaseLayout>
        <main>{children}</main>
      </BaseLayout>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
